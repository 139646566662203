import Link from 'components/base/Link'
import Tooltip from 'components/base/Tooltip'
import { NavItem } from 'configs/navbar'
import { c, FC } from 'lib/component-utils'
import { useUserContext } from 'lib/context'
import { useIntl } from 'lib/intl-utils'
import { useSetAtom } from 'jotai'
import { subnavPaneContentsAtom } from 'components/sidebar/lib/atoms'
import { useRef } from 'react'

type Props = {
  nav: NavItem
  active: boolean
  navIconClassName?: string
  external?: boolean
}

const NavLink: FC<Props> = ({ nav, active, className, navIconClassName, external, children }) => {
  const { t, lang } = useIntl()
  const { user, currentMall, theme } = useUserContext()
  const LinkComponent = external ? 'a' : Link
  const setSubnavPaneContents = useSetAtom(subnavPaneContentsAtom)
  const relevantNavItems = nav.items.filter(
    (item) =>
      user.roles.includes(item.role) &&
      (!item.malls || item.malls.includes(currentMall.code)) &&
      (!item.hosts || item.hosts.includes(theme.hostType))
  )

  const activeTimeout = useRef<NodeJS.Timeout | null>(null)

  return (
    <Tooltip content={t(nav.name)} side="right" className="large-sidebar:hidden">
      <LinkComponent
        href={
          (relevantNavItems[0]?.route ?? nav.route) + (nav.addMall ? `${currentMall.code}/` : '')
        }
        className={c`relative w-full ${className}`}
        onMouseEnter={() => {
          activeTimeout.current = setTimeout(
            relevantNavItems.length > 1
              ? () => setSubnavPaneContents(relevantNavItems)
              : () => setSubnavPaneContents(null),
            150
          )
        }}
        onMouseLeave={() => (activeTimeout.current ? clearTimeout(activeTimeout.current) : {})}
      >
        {active && (
          <div className="bg-primary-600 large-sidebar:hidden h-1/2 top-3 absolute left-0 w-[3px] rounded-r" />
        )}
        <div
          className={c`group large-sidebar:w-full large-sidebar:hover:bg-gray-50 large-sidebar:rounded-md large-sidebar:py-2 large-sidebar:px-3 large-sidebar:justify-start flex items-center justify-center w-full py-3 ${active} large-sidebar:bg-gray-100 large-sidebar:hover:bg-gray-100`}
        >
          <nav.icon
            className={c`${navIconClassName} group-hover:text-gray-900 large-sidebar:text-gray-400 large-sidebar:group-hover:text-gray-500 group-hover:duration-100 large-sidebar:-ml-1 large-sidebar:mr-3 shrink-0 w-6 h-6 transition-colors duration-200 ${active} text-black large-sidebar:text-gray-500 | text-gray-500`}
          />
          <span
            className={c`large-sidebar:block group-hover:text-gray-900 group-hover:duration-100 hidden font-medium text-gray-600 transition-colors duration-200 ${active} large-sidebar:text-gray-900`}
          >
            {t(nav.name)}
          </span>
          {children}
        </div>
      </LinkComponent>
    </Tooltip>
  )
}

export default NavLink
