import { DependencyList, EffectCallback, useEffect, useRef, useState } from 'react'
import { LocalStorage, storage } from './local-storage'

/** `useEffect` that receives an abort signal on re-render. */
export const useEffectWithAbortSignal = (
  effect: (abortSignal: AbortSignal) => void | (() => void),
  deps?: DependencyList
) => {
  useEffect(() => {
    const abortController = new AbortController()
    const effectDestructor = effect(abortController.signal)

    return () => {
      abortController.abort()
      if (typeof effectDestructor === 'function') {
        effectDestructor()
      }
    }
  }, deps)
}

/** `useEffect` that only runs on updates. */
export const useEffectOnUpdate = (effect: EffectCallback, deps?: DependencyList) => {
  const mounted = useRef(false)

  useEffect(() => {
    if (mounted.current) {
      return effect()
    }
    mounted.current = true
  }, deps)
}

/** Forces a re-render. */
export const useForceRender = () => {
  const [_, setSignal] = useState(false)

  return () => setSignal((signal) => !signal)
}

/** Returns what the value was on the previous render */
export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

export const useStateFromLocalStorage = <T extends keyof LocalStorage>(
  key: T,
  initialValue: LocalStorage[T]
) => {
  const [value, setValue] = useState<LocalStorage[T]>(initialValue)

  useEffect(() => {
    const item = storage.get(key)
    if (item) {
      setValue(item)
      return
    }
    storage.set(key, initialValue)
  }, [])

  const wrappedSetValue = (value: LocalStorage[T]) => {
    setValue(value)
    storage.set(key, value)
  }

  return [value, wrappedSetValue] as const
}
