import { useUserContext } from 'lib/context'
import NextHead from 'next/head'
import Script from 'next/script'

type Props = {
  isResponsive: boolean | undefined
}

const Head = ({ isResponsive }: Props) => {
  const { theme } = useUserContext()

  return (
    <>
      {theme.hostType === 'sellmonitor' && process.env.NODE_ENV === 'production' && (
        <Script strategy="lazyOnload" src="//code.jivosite.com/widget/si8JtpptJ7" />
      )}
      {theme.hostType === 'uzum' && process.env.NODE_ENV === 'production' && (
        <Script strategy="lazyOnload" src="//code.jivo.ru/widget/kVkcQTs8uz" />
      )}
      <NextHead>
        <meta
          name="viewport"
          content={`width=${Boolean(isResponsive) ? 'device-width' : '1170'}, initial-scale=1.0`}
        />
        <meta name="format-detection" content="telephone=no" />
        {theme.hostType === 'sellmonitor' && (
          <meta name="yandex-verification" content="9f2554909dccf0ec" />
        )}
        {theme.hostType === 'sellscreen' && (
          <meta name="facebook-domain-verification" content="olcivg7dfgvenc0fs2jgt91ulxt3ke" />
        )}
        {theme.hostType === 'sellscreen' && (
          <link rel="mask-icon" href={`/next/sellscreen/favicons/favicon.svg`} color="#00a0ff" />
        )}
        {theme.hostType === 'sellscreen' && (
          <link
            rel="icon"
            type="image/svg"
            sizes="192x192"
            href={`/next/sellscreen/favicons/favicon.svg`}
          />
        )}
        {!['sellscreen'].includes(theme.hostType) && (
          <>
            <link
              rel="shortcut icon"
              href={`/new/images/favicons/favicon_${theme.hostType}/favicon.ico`}
              type="image/x-icon"
            />
            <link
              rel="apple-touch-icon"
              href={`/new/images/favicons/favicon_${theme.hostType}/apple-touch-icon.png`}
            />
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href={`/new/images/favicons/favicon_${theme.hostType}/apple-touch-icon-180x180.png`}
            />
            <link
              rel="icon"
              type="image/png"
              sizes="192x192"
              href={`/new/images/favicons/favicon_${theme.hostType}/android-chrome-192x192.png`}
            />
            <link
              rel="icon"
              type="image/png"
              sizes="96x96"
              href={`/new/images/favicons/favicon_${theme.hostType}/android-chrome-96x96.png`}
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href={`/new/images/favicons/favicon_${theme.hostType}/favicon-32x32.png`}
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href={`/new/images/favicons/favicon_${theme.hostType}/favicon-16x16.png`}
            />
            <link
              rel="manifest"
              href={`/new/images/favicons/favicon_${theme.hostType}/manifest.json`}
            />
          </>
        )}
        {theme.hostType === 'uzum' && (
          <>
            <link
              rel="shortcut icon"
              href={`/new/images/favicons/favicon_sellmonitor/favicon.ico`}
              type="image/x-icon"
            />
            <link
              rel="apple-touch-icon"
              href={`/new/images/favicons/favicon_sellmonitor/apple-touch-icon.png`}
            />
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href={`/new/images/favicons/favicon_sellmonitor/apple-touch-icon-180x180.png`}
            />
            <link
              rel="icon"
              type="image/png"
              sizes="192x192"
              href={`/new/images/favicons/favicon_sellmonitor/android-chrome-192x192.png`}
            />
            <link
              rel="icon"
              type="image/png"
              sizes="96x96"
              href={`/new/images/favicons/favicon_sellmonitor/android-chrome-96x96.png`}
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href={`/new/images/favicons/favicon_sellmonitor/favicon-32x32.png`}
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href={`/new/images/favicons/favicon_sellmonitor/favicon-16x16.png`}
            />
            <link rel="manifest" href={`/new/images/favicons/favicon_sellmonitor/manifest.json`} />
          </>
        )}
        <link
          rel="yandex-tableau-widget"
          href={`/new/images/favicons/favicon_${theme.hostType}/yandex-browser-manifest.json`}
        />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta
          name="msapplication-TileImage"
          content={`/new/images/favicons/favicon_${theme.hostType}/mstile-144x144.png`}
        />
        <meta
          name="msapplication-config"
          content={`/new/images/favicons/favicon_${theme.hostType}/browserconfig.xml`}
        />
        <meta name="theme-color" content="#ffffff" />
        <style>{`:root { ${Object.entries(theme.themeColors)
          .map(([shade, color]) => `--color-primary-${shade}: ${color}`)
          .join('; ')} }`}</style>
      </NextHead>
    </>
  )
}

export default Head
