import { Fragment, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { FC, c } from 'lib/component-utils'

type Props = {
  open: boolean
  setOpen: (value: boolean) => void
}

const Modal: FC<Props> = ({ open, setOpen, children, className, ...rest }) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'
      return
    }
    document.body.style.overflow = ''
  }, [open])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static={true}
        className="fixed inset-0 z-[1001] flex items-center justify-center p-4"
        open={open}
        onClose={() => setOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            className={c`relative max-w-full max-h-full overflow-y-auto overflow-x-auto transition-all transform bg-white rounded-lg shadow-xl overscroll-contain ${className}`}
            {...rest}
          >
            {children}
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  )
}

export default Modal
