import { c, FC } from 'lib/component-utils'
import { useUserContext } from 'lib/context'
import { useIntl } from 'lib/intl-utils'
import { additionalRoutes, navbar } from 'configs/navbar'
import { useRouter } from 'next/router'
import { ChevronLeftIcon, ChevronRightIcon, TranslateIcon } from '@heroicons/react/outline'
import Tooltip from 'components/base/Tooltip'
import { setCookie } from 'nookies'
import ItemGroup from './ItemGroup'
import NavLink from './NavLink'
import { Fragment, memo } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import DropdownMenu from 'components/base/DropdownMenu'
import Link from 'components/base/Link'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import {
  sidebarIsCollapsibleAtom,
  sidebarIsLargeAtom,
  sidebarSizeSettingAtom,
  subnavPaneContentsAtom,
} from 'components/sidebar/lib/atoms'
import SubnavPane from 'components/sidebar/SubnavPane'
import { navItemIsActive } from 'components/sidebar/lib/navItems'

type Props = {
  setMallsOpen: (to: (prev: boolean) => boolean) => void
}

const Sidebar: FC<Props> = memo(({ setMallsOpen }) => {
  const { user, currentMall, theme } = useUserContext()
  const { t, lang, format } = useIntl()
  const router = useRouter()
  const updateSidebarSizeSetting = useSetAtom(sidebarSizeSettingAtom)
  const sidebarIsLarge = useAtomValue(sidebarIsLargeAtom)
  const sidebarIsCollapsible = useAtomValue(sidebarIsCollapsibleAtom)
  const [subnavPaneContents, setSubnavPaneContents] = useAtom(subnavPaneContentsAtom)

  const help = navbar.find((item) => item.name === 'help' && item.hosts?.includes(theme.hostType))!
  const [settings, logout, switchedLogout] = additionalRoutes

  const setLanguage = (code: string) => {
    setCookie(null, 'lang', code, {
      maxAge: 365 * 24 * 60 * 60,
      path: '/',
    })
    router.reload()
  }

  const languageOptions = [
    { code: 'ru', name: 'Русский' },
    { code: 'en', name: 'English' },
    { code: 'zh', name: '中文' },
    { code: 'uz', name: "O'zbek" },
  ]

  return (
    <nav className="ring-1 ring-gray-200 bg-white sticky z-[1001] top-0 h-screen shadow-sm">
      <div
        onMouseLeave={() => setSubnavPaneContents(null)}
        className="flex relative flex-col place-content-start py-3 w-12 h-full bg-white large-sidebar:w-56"
      >
        <SubnavPane className="fixed z-[-1] left-[calc(3rem+1px)] large-sidebar:left-[calc(14rem+1px)] top-0" />
        <ItemGroup>
          <Link href="/products/list/" onMouseEnter={() => setSubnavPaneContents(null)}>
            <img
              className="p-2.5 large-sidebar:hidden"
              src={theme.logoFixed}
              alt={`${theme.title} logo`}
            />
            <img
              className="hidden pt-3 pb-4 pl-2 w-40 large-sidebar:block"
              src={theme.logoDark}
              alt={`${theme.title} logo`}
            />
          </Link>
        </ItemGroup>
        <ItemGroup className="mt-4 large-sidebar:mt-4">
          <Tooltip content={t`change_mall`} side="right" className="large-sidebar:hidden">
            <button
              onMouseEnter={() => setSubnavPaneContents(null)}
              onClick={() =>
                setMallsOpen((v) => {
                  return !v
                })
              }
              className="relative w-full"
            >
              <div className="flex justify-center items-center w-full group large-sidebar:w-full large-sidebar:hover:bg-gray-50 large-sidebar:rounded-md large-sidebar:py-2 large-sidebar:px-3 large-sidebar:justify-start">
                <img
                  className="p-[11px] large-sidebar:p-0 large-sidebar:w-6 large-sidebar:h-6 shrink-0 large-sidebar:-ml-1 large-sidebar:mr-3"
                  src={`/img/mall_ico/${currentMall?.code}.png`}
                  alt={`${currentMall.description} logo`}
                />
                <div className="flex flex-col text-left">
                  <span className="hidden font-medium text-gray-600 large-sidebar:inline group-hover:text-gray-900">
                    {currentMall.description}
                  </span>
                  <span className="whitespace-nowrap group-hover:text-gray-600 large-sidebar:inline hidden text-[11px] text-gray-500">{t`change_mall`}</span>
                </div>
                <ChevronRightIcon className="hidden absolute right-2 w-4 h-4 text-gray-500 large-sidebar:block shrink-0" />
              </div>
            </button>
          </Tooltip>
        </ItemGroup>
        <ItemGroup className="mt-1 large-sidebar:mt-1">
          {navbar
            .filter((item) => item.name !== 'help')
            .map((nav) => {
              return (
                user.roles.includes(nav.role) &&
                (!nav.malls || nav.malls.includes(currentMall.code)) &&
                (!nav.hosts || nav.hosts.includes(theme.hostType)) &&
                nav.items.some(
                  (item) =>
                    user.roles.includes(item.role) &&
                    (!item.malls || item.malls.includes(currentMall.code)) &&
                    (!item.hosts || item.hosts.includes(theme.hostType))
                ) && <NavLink key={nav.name} nav={nav} active={navItemIsActive(nav, router)} />
              )
            })}
        </ItemGroup>
        <ItemGroup className="mt-auto large-sidebar:px-0">
          <NavLink
            nav={help}
            active={navItemIsActive(help, router)}
            className="large-sidebar:px-2"
          />
          <Listbox
            as="div"
            className="relative w-full"
            value={languageOptions.find((item) => item.code === lang)!}
            onChange={(value) => setLanguage(value.code)}
          >
            {({ open }) => (
              <>
                <Tooltip content={t`language`} side="right" className="large-sidebar:hidden">
                  <Listbox.Button
                    className="relative w-full large-sidebar:px-2"
                    onMouseEnter={() => setSubnavPaneContents(null)}
                  >
                    <div
                      className={c`flex justify-center items-center py-3 w-full group large-sidebar:w-full large-sidebar:hover:bg-gray-50 large-sidebar:rounded-md large-sidebar:py-2 large-sidebar:px-3 large-sidebar:justify-start`}
                    >
                      <TranslateIcon
                        className={c`w-6 h-6 text-gray-500 transition-colors duration-200 group-hover:text-gray-900 large-sidebar:text-gray-400 large-sidebar:group-hover:text-gray-500 group-hover:duration-100 large-sidebar:-ml-1 large-sidebar:mr-3 shrink-0`}
                      />
                      <span
                        className={c`hidden font-medium text-gray-600 transition-colors duration-200 large-sidebar:block group-hover:text-gray-900 group-hover:duration-100`}
                      >
                        {t`language`}
                      </span>
                      <ChevronRightIcon className="hidden absolute right-4 top-1/2 w-4 h-4 text-gray-500 -translate-y-1/2 large-sidebar:block shrink-0" />
                    </div>
                  </Listbox.Button>
                </Tooltip>
                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <DropdownMenu
                    options={languageOptions}
                    display="name"
                    optionKey="code"
                    className="left-[calc(100%+0.5rem)] -top-9 large-sidebar:-top-11"
                  />
                </Transition>
              </>
            )}
          </Listbox>
          <NavLink
            nav={settings}
            active={navItemIsActive(settings, router)}
            className="large-sidebar:px-2"
          />
          <NavLink
            external
            nav={user.switched ? switchedLogout : logout}
            active={navItemIsActive(logout, router)}
            className="large-sidebar:px-2"
            navIconClassName="large-sidebar:mr-2.5 ml-[3px]"
          >
            {user.switched && (
              <div className="absolute top-1 right-1 size-2 rounded-full bg-red-500" />
            )}
          </NavLink>
          {sidebarIsCollapsible && (
            <Tooltip
              content={sidebarIsLarge ? t`collapse` : t`expand`}
              side="right"
              className="large-sidebar:hidden"
            >
              <button
                className={c`relative w-full large-sidebar:px-2`}
                onClick={() => updateSidebarSizeSetting(sidebarIsLarge ? 'compact' : 'large')}
                onMouseEnter={() => setSubnavPaneContents(null)}
              >
                <div
                  className={c`flex justify-center items-center py-3 w-full group large-sidebar:w-full large-sidebar:hover:bg-gray-50 large-sidebar:rounded-md large-sidebar:py-2 large-sidebar:px-3 large-sidebar:justify-start`}
                >
                  {sidebarIsLarge ? (
                    <ChevronLeftIcon
                      className={`w-6 h-6 text-gray-500 transition-colors duration-200 group-hover:text-gray-900 large-sidebar:text-gray-400 large-sidebar:group-hover:text-gray-500 group-hover:duration-100 large-sidebar:-ml-1 large-sidebar:mr-3 shrink-0`}
                    />
                  ) : (
                    <ChevronRightIcon
                      className={`w-6 h-6 text-gray-500 transition-colors duration-200 group-hover:text-gray-900 large-sidebar:text-gray-400 large-sidebar:group-hover:text-gray-500 group-hover:duration-100 large-sidebar:-ml-1 large-sidebar:mr-3 shrink-0`}
                    />
                  )}
                  <span
                    className={c`hidden font-medium text-gray-600 transition-colors duration-200 large-sidebar:block group-hover:text-gray-900 group-hover:duration-100`}
                  >
                    {sidebarIsLarge ? t`collapse` : t`expand`}
                  </span>
                </div>
              </button>
            </Tooltip>
          )}
        </ItemGroup>
      </div>
    </nav>
  )
})

Sidebar.displayName = 'Sidebar'
export default Sidebar
