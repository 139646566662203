import Modal from 'components/base/Modal'
import { useAtom } from 'jotai'
import { useSetAtom } from 'jotai'
import { currentModalAtom } from 'lib/atoms'
import { FC } from 'lib/component-utils'

export const useModal = () => {
  const set = useSetAtom(currentModalAtom)

  return [
    (modal: JSX.Element) =>
      set((state) => {
        state.contents = modal
        state.shown = true
      }),
    () =>
      set((state) => {
        state.shown = false
        state.contents = null
      }),
  ] as const
}

const ModalDispatcher: FC = () => {
  const [{ shown, contents }, set] = useAtom(currentModalAtom)

  return (
    <Modal
      open={shown}
      setOpen={(value) =>
        set((state) => {
          state.shown = value
        })
      }
    >
      {contents}
    </Modal>
  )
}

export default ModalDispatcher
