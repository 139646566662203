import { Listbox } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/solid'
import { c } from 'lib/component-utils'
import { DisplayExtractor, extract, KeyExtractor } from 'lib/type-utils'
import { ForwardedRef, forwardRef } from 'react'

type Props<T> = {
  options: T[]
  optionKey?: KeyExtractor<T>
  display?: DisplayExtractor<T>
  className?: string
}

const DropdownMenu = <T,>(
  { options, optionKey, display, className }: Props<T>,
  ref: ForwardedRef<HTMLUListElement>
) => {
  return (
    <Listbox.Options
      ref={ref}
      className={c`bg-white max-h-64 overscroll-contain ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm absolute z-20 w-48 mt-2 overflow-auto text-base rounded-md shadow-lg ${className}`}
    >
      {options.map((option) => (
        <Listbox.Option
          key={extract(option, optionKey)}
          className={({ active }) =>
            c`cursor-pointer select-none relative py-2.5 pl-8 pr-4 text-left ${active} text-black bg-gray-100 | text-gray-900`
          }
          value={option}
        >
          {({ selected, active }) => (
            <>
              <span className={c`block truncate ${selected} font-medium`}>
                {extract(option, display)}
              </span>
              {selected && (
                <span
                  className={c`absolute inset-y-0 left-0 flex items-center pl-3 z-10 text-primary-500 ${active} !text-primary-600`}
                >
                  <CheckIcon className="w-4 h-4" aria-hidden="true" />
                </span>
              )}
            </>
          )}
        </Listbox.Option>
      ))}
    </Listbox.Options>
  )
}

export default forwardRef<HTMLUListElement, {}>(DropdownMenu as any) as <T>(
  { options, optionKey, display, className }: Props<T>,
  ref: ForwardedRef<HTMLUListElement>
) => JSX.Element
